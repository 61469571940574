<template>
  <div>
    <video
      ref="video"
      v-show="playing"
      @ended="reset"
      class="video"
    >
      <source src="../assets/videos/byebye.mp4" />
    </video>
    <button
      v-if="!playing"
      @click="play"
      class="play-button"
    >
      Click me!
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    playing: false
  }),
  methods: {
    play () {
      this.playing = true
      this.$refs.video.play()
    },
    reset () {
      this.playing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.play-button {
  display: flex;
  padding: 10px 16px 4px 16px;
  border: none;
  box-shadow: none;
  outline: none;
  font-family: 'Josefin Slab', serif;
  font-weight: 500;
  font-size: 32px;
  background-color: #e91e63;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.video {
  margin-top: -16px;
  max-height: calc(100vh - 32px);
  border-radius: 5px;
  box-shadow: 0 4px 7px 0px rgba(#a3a2bf, 0.5)
}
</style>
