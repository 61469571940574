<template>
  <div id="app">
    <div class="video-wrapper">
      <Video />
    </div>
  </div>
</template>

<script>
import Video from './components/Video.vue'

export default {
  name: 'App',
  components: {
    Video
  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}
</style>
